import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../AppContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const Form = styled.form`
  width: 80%;
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.color ? props.color : '#007bff')};
  color: ${(props) => (props.color === '#ccc' ? '#333' : 'white')};
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: ${(props) => (props.color === '#ccc' ? 'none' : '1px solid transparent')};
  width: 200px;

  &:hover {
    background-color: ${(props) => (props.color === '#ccc' ? '#bbb' : props.color)};
  }
`;

const YesButton = styled(Button)`
  background-color: green;
  font-weight: bold;

  &:hover {
    background-color: darkgreen;
  }
`;

const BirthdayInput: React.FC = () => {
  const [birthday, setBirthday] = useState('');
  const navigate = useNavigate();
  const { setBirthday: setAppBirthday } = useAppContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirthday(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setAppBirthday(birthday);
    navigate("/VehiculoEleccion/");
  };

  return (
    <>
      <Header title="Ingresar Fecha de Nacimiento" />
      <Wrapper>
        <Title>Ingrese su fecha de nacimiento</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="date"
            value={birthday}
            onChange={handleChange}
            placeholder="Fecha de Nacimiento"
          />
          <ButtonRow>
            <Button color="#ccc" type="button" onClick={() => navigate("/AseguradoraEleccion/")}>Cancelar</Button>
            <YesButton type="submit">Confirmar</YesButton>
          </ButtonRow>
        </Form>
      </Wrapper>
    </>
  );
};

export default BirthdayInput;
