import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import qs from 'qs';

const Trabajador: React.FC = () => {
  const alldata = useAppContext();
  const [queue, setQueue] = useState<any[]>([]);
  const navigate = useNavigate();
  
  
  const fetchQueue = async () => {
    try {
      const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const dd = String(today.getDate()).padStart(2, "0");
        return `${yyyy}-${mm}-${dd}`;
      };

      const currentDate = getCurrentDate();
      console.log('Current date:', currentDate);
      const filter = {
        offset: 0,
        limit: 100,
        skip: 0,
       // where: { creationDate: currentDate },
        fields: {
          apellidos: true,
        cAmis: true,
        cartype: true,
        changeDate: true,
        ciudad: true,
        cobertura: true,
        codigoPostal: true,
        colonia: true,
        correo: true,
        creationDate: true,
        curp: true,
        domicilio: true,
        driverId: true,
        estado: true,
        expedicionlicencia: true,
        expiracionlicencia: true,
        fechanacimiento: true,
        ineAtrasPhoto: true,
        ineEnfrentePhoto: true,
        insuranceCompany: true,
        linea: true,
          marca: true,
          modelo: true,
        nombres: true,
        numerolicencia: true,
        numeroPoliza: true,
          origen: true,
        pagado: true,
        pais: true,
        placa: true,
        polizaArchivo: true,
          rfc: true,
        serie: true,
        sex: true,
          tarjetaCirculacionPhoto: true,
        telefono: true,
        version: true
        }
      };

    // Convert the filter object to a query string
      const queryString = qs.stringify({ filter }, { encode: true, arrayFormat: 'brackets' });
      const response = await axios.get(`https://www.gruporomeroseguros.com/api/drivers?${queryString}`);
      console.log('API response:', response.data);

    // Ensure response.data is an array before setting the queue
      if (Array.isArray(response.data)) {
        setQueue(response.data);
      } else {
        console.error('Expected an array but got:', response.data);
      }
    } catch (error) {
      console.error("Error fetching queue:", error);
    }
  };

  useEffect(() => {
    fetchQueue();
    const interval = setInterval(fetchQueue, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {queue.map((client, index) => (
        <div 
          key={index} 
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'baseline',
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            marginBottom: '15px',
            backgroundColor: '#f9f9f9',
  maxWidth: '800px',
  width: '100%',
  margin: '0 auto'
          }}
        >
          <div style={{ flex: 1 }}>
            <h3>{client.nombres} {client.apellidos}</h3>
          </div>
          <button 
            style={{
              padding: '8px 16px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#0056b3')}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#007bff')}
            onClick={() => {
              console.log("Selected client:", client);
              alldata.setPlateNumber(client.placa);
              alldata.setScrapedData({
                serie: client.serie,
                origen: client.origen,
                marca: client.marca,
                linea: client.linea,
                version: client.version,
                modelo: client.modelo,
              });
              alldata.setParsedData({
                apellidos: client.apellidos,
                nombres: client.nombres,
                domicilio: client.domicilio,
                colonia: client.colonia,
                codigoPostal: client.codigoPostal,
                ciudad: client.ciudad,
                estado: client.estado,
                curp: client.curp,
              });
              alldata.setInputInfo({
                telefono: client.telefono,
                correo: client.correo,
                rfc: client.rfc,
              });
              alldata.settipovehiculo(client.cartype);
              alldata.setaseguradora(client.insuranceCompany);
              const currentDate = new Date();
              const formattedDate = currentDate.toLocaleDateString('en-CA', { timeZone: 'America/Hermosillo' });
              console.log('Formatted date:', formattedDate);
              axios.get(`https://www.gruporomeroseguros.com/photo/${formattedDate}/${client.tarjetaCirculacionPhoto}`).then(
                (response) => { 
                  alldata.setCirculacionPhoto(response.data); 
                }).finally(() => {
                  axios.get(`https://www.gruporomeroseguros.com/photo/${formattedDate}/${client.ineEnfrentePhoto}`).then(
                    (response) => { 
                      alldata.setIneEnfrentePhoto(response.data); 
                    }).finally(() => {
                      axios.get(`https://www.gruporomeroseguros.com/photo/${formattedDate}/${client.ineAtrasPhoto}`).then(
                        (response) => { 
                          alldata.setIneAtrasPhoto(response.data); 
                        }).finally(() => {
                              navigate(`/TablaFila/`);        
                        });
                    });
                }); 
            }}
          >
            Seleccionar
          </button>
        </div>
      ))}
    </>
  );
};

export default Trabajador;
