import React, { createContext, useContext, useState } from 'react';

interface AppState {
  devices: MediaDeviceInfo[];
  setDevices: React.Dispatch<React.SetStateAction<MediaDeviceInfo[]>>;
  plateNumber: string ;
  setPlateNumber: React.Dispatch<React.SetStateAction<string >>;
  tipovehiculo: string ;
  settipovehiculo: React.Dispatch<React.SetStateAction<string >>;
  aseguradora: string ;
  setaseguradora: React.Dispatch<React.SetStateAction<string >>;
  genero: string ;
  setgenero: React.Dispatch<React.SetStateAction<string >>;
  scrapedData: any;
  setScrapedData: React.Dispatch<React.SetStateAction<any>>;
  circulacionPhoto: string ;
  setCirculacionPhoto: React.Dispatch<React.SetStateAction<string >>;
  circulacionPhotoNombre: string ;
  setCirculacionPhotoNombre: React.Dispatch<React.SetStateAction<string >>;
  ineAtrasPhoto: string ;
  setIneAtrasPhoto: React.Dispatch<React.SetStateAction<string >>;
  ineAtrasPhotoNombre: string ;
  setIneAtrasPhotoNombre: React.Dispatch<React.SetStateAction<string >>;
  ineEnfrentePhoto: string ;
  setIneEnfrentePhoto: React.Dispatch<React.SetStateAction<string >>;
  ineEnfrentePhotoNombre: string ;
  setIneEnfrentePhotoNombre: React.Dispatch<React.SetStateAction<string >>;
  inputInfo: { telefono: string; correo: string; rfc: string } ;
  setInputInfo: React.Dispatch<React.SetStateAction<{ telefono: string; correo: string; rfc: string } >>;
  parsedData: any;
  setParsedData: React.Dispatch<React.SetStateAction<any>>;
  insuranceCompany: string ;
  setInsuranceCompany: React.Dispatch<React.SetStateAction<string >>;
  numerolicencia: string ;
  setNumerolicencia: React.Dispatch<React.SetStateAction<string >>;
  pais: string ;
  setPais: React.Dispatch<React.SetStateAction<string >>;
  placats: string ;
  setPlacats: React.Dispatch<React.SetStateAction<string >>;
  birthday: string;
  setBirthday: React.Dispatch<React.SetStateAction<string>>;
}

const AppContext = createContext<AppState | undefined>(undefined);

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [plateNumber, setPlateNumber] = useState<string>('');
  const [scrapedData, setScrapedData] = useState<any>(null);
  const [circulacionPhoto, setCirculacionPhoto] = useState<string>('');
  const [ineAtrasPhoto, setIneAtrasPhoto] = useState<string>('');
  const [ineEnfrentePhoto, setIneEnfrentePhoto] = useState<string>('');
  const [inputInfo, setInputInfo] = useState<{ telefono: string; correo: string; rfc: string }>({ telefono: '', correo: '', rfc: '' });
  const [parsedData, setParsedData] = useState<any>(null);
  const [circulacionPhotoNombre, setCirculacionPhotoNombre] = useState<string>('');
  const [ineAtrasPhotoNombre, setIneAtrasPhotoNombre] = useState<string>('');
  const [ineEnfrentePhotoNombre, setIneEnfrentePhotoNombre] = useState<string>('');
  const [tipovehiculo, settipovehiculo] = useState<string>('');
  const [aseguradora, setaseguradora] = useState<string>('');
  const [genero, setgenero] = useState<string>('');
  const [insuranceCompany, setInsuranceCompany] = useState<string>('');
  const [numerolicencia, setNumerolicencia] = useState<string>('');
  const [pais, setPais] = useState<string>('');
  const [placats, setPlacats] = useState<string>('');
  const [birthday, setBirthday] = useState<string>('');

  return (
    <AppContext.Provider
      value={{
        devices,
        setDevices,
        plateNumber,
        setPlateNumber,
        tipovehiculo,
        settipovehiculo,
        aseguradora,
        setaseguradora,
        genero,
        setgenero,
        scrapedData,
        setScrapedData,
        circulacionPhoto,
        setCirculacionPhoto,
        circulacionPhotoNombre,
        setCirculacionPhotoNombre,
        ineEnfrentePhotoNombre,
        setIneEnfrentePhotoNombre,
        ineAtrasPhotoNombre,
        setIneAtrasPhotoNombre,
        ineAtrasPhoto,
        setIneAtrasPhoto,
        ineEnfrentePhoto,
        setIneEnfrentePhoto,
        inputInfo,
        setInputInfo,
        parsedData,
        setParsedData,
        insuranceCompany,
        setInsuranceCompany,
        numerolicencia,
        setNumerolicencia,
        placats,
        setPlacats,
        pais,
        setPais,
        birthday,
        setBirthday,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, useAppContext };