import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import ImageComponent from '../components/ImageComponent';
import axios from 'axios';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: calc(100vh - 60px);
  background-color: #f0f0f0;
  padding: 20px 15px;
  box-sizing: border-box;
  overflow-x: auto;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  min-width: 1700px; /* Increased for new column */
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  padding: 10px;
  background-color: #007bff;
  color: white;
  text-align: center;
  font-size: 14px;
  word-break: break-word;
  min-width: 150px;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
  text-align: center;
  word-break: break-word;
  min-width: 150px;
  max-width: 250px;
  white-space: pre-wrap;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  padding: 5px;
  text-align: center;
`;

const Select = styled.select`
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  padding: 5px;
  text-align: center;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const Button = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.color ? props.color : '#007bff')};
  color: white;

  &:hover {
    background-color: ${(props) => (props.color === 'gray' ? '#bbb' : '#0056b3')};
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const Modal = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ModalButton = styled(Button)<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  width: 45%;
`;

const Tr = styled.tr<{ isPaid?: boolean }>`
  &:nth-child(even) {
    background-color: ${props => props.isPaid ? '#d1d1d1' : '#f2f2f2'};
  }
  &:nth-child(odd) {
    background-color: ${props => props.isPaid ? '#66D05C' : '#ffffff'};
  }
  transition: background-color 0.3s ease;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #f0f0f0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const ActionButton = styled.button`
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: white;
  transition: all 0.3s ease;
  min-width: 150px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
  }
`;

const AddButton = styled(ActionButton)`
  background-color: #007bff;
  &:hover {
    background-color: #0056b3;
  }
`;

const ExportButton = styled(ActionButton)`
  background-color: #28a745;
  &:hover {
    background-color: #218838;
  }
`;

const TrashIcon = styled.span`
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  color: white;
`;

// Define Clave options
const ClaveOptions = [
  '', // Empty option
  'Opción 1',
  'Opción 2',
  'Opción 3',
  'Opción 4',
  'Opción 5'
];

interface DataRow {
  numero: string;
  apellidos: string;
  nombres: string;
  cobertura: string;
  monto: string;
  placas: string;
  serie: string;
  origen: string;
  clave: string;
  marca: string;
  linea: string;
  version: string;
  modelo: string;
  domicilio: string;
  colonia: string;
  codigoPostal: string;
  telefono: string;
  correo: string;
  rfc: string;
  numeroPoliza: string;
  polizaArchivo: string;
  aseguradora: string;
  tipovehiculo: string;
  pagado: boolean;
  INE_front: string;
  INE_back: string;
  TarjetaCirculacion: string;
  estado: string;
  ciudad: string;
  curp: string;
  insuranceCompany: string;
  numerolicencia: string;
  pais: string;
  placa: string; // Add placa property
}

interface TablaFilaProps {
  data: DataRow[];
}

type ModalType = 'generate' | 'delete' | null;

const TablaFila: React.FC<TablaFilaProps> = ({ data }) => {
  const [editedData, setEditedData] = useState(data);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [selectedRow, setSelectedRow] = useState<{ data: DataRow; index: number } | null>(null);
  const [claveOptions, setClaveOptions] = useState(ClaveOptions);
  const [selectedClave, setSelectedClave] = useState('');
  const [AxiosResponse, setAxiosResponse] = useState();
  const [showClaveOptions, setShowClaveOptions] = useState(false);
  const [showCotizarButton, setShowCotizarButton] = useState(false);

  const createEmptyRow = (): DataRow => {
    const lastRow = editedData[editedData.length - 1];
    const nextNumber = lastRow 
      ? String(Number(lastRow.numero) + 1)
      : "1";

    return {
      numero: nextNumber,
      apellidos: "",
      nombres: "",
      cobertura: "",
      monto: "",
      placas: "",
      serie: "",
      origen: "",
      clave: "",
      marca: "",
      linea: "",
      version: "",
      modelo: "",
      domicilio: "",
      colonia: "",
      codigoPostal: "",
      telefono: "",
      correo: "",
      rfc: "",
      numeroPoliza: "",
      polizaArchivo: "",
      aseguradora: "",
      tipovehiculo: "",
      pagado: false,
      INE_front: "",
      INE_back: "",
      TarjetaCirculacion: "",
      estado: "",
      ciudad: "",
      curp: "",
      insuranceCompany: "",
      numerolicencia: "",
      pais: "",
      placa: "",
    };
  };

  const handleEdit = (index: number, key: keyof DataRow, value: string | boolean) => {
    const newData = [...editedData];
    newData[index][key] = value as never;
    setEditedData(newData);
  };

  const handleCheckboxChange = (index: number) => {
    const newData = [...editedData];
    newData[index].pagado = !newData[index].pagado;
    setEditedData(newData);
  };

  const handleGenerarClick = (row: DataRow) => {
    setSelectedRow({ data: row, index: editedData.indexOf(row) });
    setModalType('generate');
    setModalOpen(true);
    setShowClaveOptions(false);
    setShowCotizarButton(false);
  };

  const handleGetAmisCodes = async () => {
    if (selectedRow) {
      console.log('Get AMIS Codes for:');
      console.log('selectedRow data: ', selectedRow.data);
      console.log('selectedRow data cartype: ', selectedRow.data.tipovehiculo);
      console.log('selectedRow data aseguradora: ', selectedRow.data.aseguradora);
      try {
        const response = await axios.post("https://www.gruporomeroseguros.com/amis/", {
          modelo: selectedRow.data.modelo,
          marca: selectedRow.data.marca,
          linea: selectedRow.data.linea,
          cartype: selectedRow.data.tipovehiculo,
          aseguradora: selectedRow.data.aseguradora,
        });
        console.log("response", response.data);
        const claveOptions = response.data.map((item: any) => item.camis||item.cveamis);
        console.log("claveOptions", claveOptions);
        setAxiosResponse(response.data);
        // Update the selected row with the new clave and claveOptions
        const newData = [...editedData];
        setEditedData(newData);
        setClaveOptions(claveOptions);
        setShowClaveOptions(true);
        setShowCotizarButton(true);
      } catch (error) {
        console.error('Error fetching AMIS codes:', error);
      }
    }
  };

  const handleDeleteClick = (row: DataRow, index: number) => {
    setSelectedRow({ data: row, index });
    setModalType('delete');
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalType(null);
    setSelectedRow(null);
  };

  const handleConfirmGenerate = async () => {
    if (selectedRow !== null) {
      if (selectedClave === '') {
        alert('Please select a clave option.');
        return;
      }
      const newData = [...editedData];
      newData[selectedRow.index].clave = selectedClave;
      setEditedData(newData);

      // Update selectedRow with the new clave value
      const updatedRow = { ...selectedRow.data, clave: selectedClave };
      setSelectedRow({ ...selectedRow, data: updatedRow });

      // send info to backend to generate policy to insurance
      console.log('Generar póliza for:');
      console.log('selectedRow data: ', updatedRow);
      try {
        let response = await axios.post('https://www.gruporomeroseguros.com/emision', {
          ...updatedRow,
          cAmis: selectedClave,
          creationDate: new Date().toISOString(),
          expiracionlicencia: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
        });
        console.log('Póliza generada:', response);
        alert(`Póliza generada: ${response.data}`);
      } catch (error) {
        console.error('Error generating policy:', error);
      }
    }
    handleCloseModal();
  };

  const handleConfirmDelete = () => {
    if (selectedRow !== null) {
      const newData = editedData.filter((_, index) => index !== selectedRow.index);
      setEditedData(newData);
    }
    handleCloseModal();
  };

  const handleClaveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setSelectedClave(newValue);
    if (selectedRow) {
      const updatedRow = { ...selectedRow.data, clave: newValue };
      setSelectedRow({ ...selectedRow, data: updatedRow });
    }
  };

  const renderModal = () => {
    if (!modalOpen || !modalType) return null;

    return (
      <>
        <ModalOverlay>
          <Modal>
            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
              {renderClaveOptions()}
            </div>
            {modalType === 'generate' && showClaveOptions && (
              <Select
                value={selectedClave}
                onChange={handleClaveChange} // Use handleClaveChange here
              >
                {claveOptions.map((option, optIndex) => (
                  <option key={optIndex} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            )}
            <ButtonRow>
              <ModalButton color="gray" onClick={handleCloseModal}>
                Cancelar
              </ModalButton>
              {!showClaveOptions && (
                <ModalButton color="blue" bold onClick={handleGetAmisCodes}>
                  Get AMIS Codes
                </ModalButton>
              )}
              {showClaveOptions && (
                <ModalButton 
                  color="green" 
                  bold 
                  onClick={modalType === 'generate' ? handleConfirmGenerate : handleConfirmDelete}
                >
                  {modalType === 'generate' ? 'Generar ' : 'Confirmar'}
                </ModalButton>
              )}
              {showCotizarButton && (
                <ModalButton color="green" 
                  bold onClick={async () => {
                    if (selectedRow !== null) {
                      const newData = [...editedData];
                      newData[selectedRow.index].clave = selectedClave;
                      setEditedData(newData);
                
                      // Update selectedRow with the new clave value
                      const updatedRow = { ...selectedRow.data, clave: selectedClave };
                      setSelectedRow({ ...selectedRow, data: updatedRow });
                
                      // send info to backend to get price from insurance
                      console.log('Cotizar for:');
                      console.log('selectedRow data: ', updatedRow);
                      try {
                        const response = await axios.post('https://www.gruporomeroseguros.com/cotisacion', {
                          ...updatedRow,
                          cAmis: selectedClave,
                          creationDate: new Date().toISOString(),
                          expiracionlicencia: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
                        });
                        setModalOpen(false);
                        alert(`Cotización generada: ${response.data}`);
                        console.log('Cotización generada:', response.data);
                      } catch (error) {
                        console.error('Error generating cotisacion:', error);
                        setModalOpen(false);
                        alert('Error generating cotisacion');
                      }
                    }
                  }}>Cotizar</ModalButton>
              )}
            </ButtonRow>
          </Modal>
        </ModalOverlay>
      </>
    );
  };

  interface AxiosResponseOption {
    camis: string;
    cVersion: string;
  }

  const renderClaveOptions = (): JSX.Element | null => {
    if (!AxiosResponse) return null;
  
    return (
      <>
        {(AxiosResponse as any[]).map((option, index) => (
          <div key={index}>
            <p>cveamis: {option.cveamis||option.camis}</p>
            <p>descrip: {option.descrip||option.cVersion}</p>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <Header title="Datos de Usuarios" />
      <Wrapper>
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>Numero</Th>
                <Th>Pagado</Th>
                <Th>Apellido(s)</Th>
                <Th>Nombre(s)</Th>
                <Th>Cobertura</Th>
                <Th>Monto</Th>
                <Th>Placas</Th>
                <Th>Serie</Th>
                <Th>Origen</Th>
                <Th>AMIS</Th>
                <Th>Marca</Th>
                <Th>Línea</Th>
                <Th>Versión</Th>
                <Th>Modelo</Th>
                <Th>Domicilio</Th>
                <Th>Colonia</Th>
                <Th>Código Postal</Th>
                <Th>Teléfono</Th>
                <Th>Correo</Th>
                <Th>RFC</Th>
                <Th>Numero Poliza</Th>
                <Th>Poliza Archivo</Th>
                <Th>Aseguradora</Th>
                <Th>Tipo de carro</Th>
                <Th>Ine (Enfrente)</Th>
                <Th>Ine (Atras)</Th>
                <Th>Tarjeta de Circulacion</Th>
                <Th>Estado</Th>
                <Th>Ciudad</Th>
                <Th>Generar</Th>
                <Th>Eliminar</Th>
              </tr>
            </thead>
            <tbody>
              {editedData.map((row, index) => (
                <Tr key={index} isPaid={row.pagado}>
                  <Td>{row.numero}</Td>
                  <Td>
                    <Checkbox
                      checked={row.pagado}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.apellidos}
                      onChange={(e) => handleEdit(index, 'apellidos', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.nombres}
                      onChange={(e) => handleEdit(index, 'nombres', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.cobertura}
                      onChange={(e) => handleEdit(index, 'cobertura', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.monto}
                      onChange={(e) => handleEdit(index, 'monto', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.placas}
                      onChange={(e) => handleEdit(index, 'placas', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.serie}
                      onChange={(e) => handleEdit(index, 'serie', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.origen}
                      onChange={(e) => handleEdit(index, 'origen', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.clave}
                      onChange={(e) => handleEdit(index, 'clave', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.marca}
                      onChange={(e) => handleEdit(index, 'marca', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.linea}
                      onChange={(e) => handleEdit(index, 'linea', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.version}
                      onChange={(e) => handleEdit(index, 'version', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.modelo}
                      onChange={(e) => handleEdit(index, 'modelo', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.domicilio}
                      onChange={(e) => handleEdit(index, 'domicilio', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.colonia}
                      onChange={(e) => handleEdit(index, 'colonia', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.codigoPostal}
                      onChange={(e) => handleEdit(index, 'codigoPostal', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.telefono}
                      onChange={(e) => handleEdit(index, 'telefono', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.correo}
                      onChange={(e) => handleEdit(index, 'correo', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.rfc}
                      onChange={(e) => handleEdit(index, 'rfc', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.numeroPoliza}
                      onChange={(e) => handleEdit(index, 'numeroPoliza', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.polizaArchivo}
                      onChange={(e) => handleEdit(index, 'polizaArchivo', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.aseguradora}
                      onChange={(e) => handleEdit(index, 'aseguradora', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.tipovehiculo}
                      onChange={(e) => handleEdit(index, 'tipovehiculo', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <ImageComponent data={row.INE_front} />
                  </Td>
                  <Td>
                    <ImageComponent data={row.INE_back} />
                  </Td>
                  <Td>
                    <ImageComponent data={row.TarjetaCirculacion} />
                  </Td>
                  <Td>
                    <Input
                      value={row.estado}
                      onChange={(e) => handleEdit(index, 'estado', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Input
                      value={row.ciudad}
                      onChange={(e) => handleEdit(index, 'ciudad', e.target.value)}
                    />
                  </Td>
                  <Td>
                    <Button onClick={() => handleGenerarClick(row)}>Generar</Button>
                  </Td>
                  <Td>
                    <Button 
                      onClick={() => handleDeleteClick(row, index)}
                      style={{ backgroundColor: '#dc3545', padding: '8px' }}
                    >
                      <TrashIcon>🗑️</TrashIcon>
                    </Button>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        {renderModal()}
        {showClaveOptions}
      </Wrapper>
    </>
  );
};

export default TablaFila;