import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 60px); /* Adjust for header height */
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const Form = styled.form`
  width: 80%;
  max-width: 600px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.color ? props.color : '#007bff')};
  color: ${(props) => (props.color === '#ccc' ? '#333' : 'white')};
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: ${(props) => (props.color === '#ccc' ? 'none' : '1px solid transparent')};
  width: 200px;

  &:hover {
    background-color: ${(props) => (props.color === '#ccc' ? '#bbb' : props.color)};
  }
`;

const YesButton = styled(Button)`
  background-color: green;
  font-weight: bold;

  &:hover {
    background-color: darkgreen;
  }
`;

interface InputConfirmarPlacasProps {
  onSubmit: (data: {
    placa: string;
    serie: string;
    origen: string;
    marca: string;
    linea: string;
    version: string;
    modelo: string;
  }) => void;
}

const InputConfirmarPlacas: React.FC<InputConfirmarPlacasProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    placa: '',
    serie: '',
    origen: '',
    marca: '',
    linea: '',
    version: '',
    modelo: '',
  });
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCancel = () => {
    setFormData({
      placa: '',
      serie: '',
      origen: '',
      marca: '',
      linea: '',
      version: '',
      modelo: '',
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <>
      <Header title="Ingresar Información de Placas" />
      <Wrapper>
        <Title>Ingrese la información del vehículo</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="placa"
            value={formData.placa}
            onChange={handleChange}
            placeholder="Placa"
          />
          <Input
            type="text"
            name="serie"
            value={formData.serie}
            onChange={handleChange}
            placeholder="Serie"
          />
          <Input
            type="text"
            name="origen"
            value={formData.origen}
            onChange={handleChange}
            placeholder="Origen"
          />
          <Input
            type="text"
            name="marca"
            value={formData.marca}
            onChange={handleChange}
            placeholder="Marca"
          />
          <Input
            type="text"
            name="linea"
            value={formData.linea}
            onChange={handleChange}
            placeholder="Línea"
          />
          <Input
            type="text"
            name="version"
            value={formData.version}
            onChange={handleChange}
            placeholder="Versión"
          />
          <Input
            type="text"
            name="modelo"
            value={formData.modelo}
            onChange={handleChange}
            placeholder="Modelo"
          />
          <ButtonRow>
            <Button color="#ccc" type="button" onClick={handleCancel}>Cancelar</Button>
            <YesButton type="submit">Confirmar</YesButton>
          </ButtonRow>
        </Form>
        <Button onClick={() => navigate("/ConfirmarPlacas/ ")}>
          Continuar a tomar foto de TarjetaCirculacion
        </Button>
      </Wrapper>
    </>
  );
};

export default InputConfirmarPlacas;
